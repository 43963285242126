<template>
  <div class="mb-5 pb-10" style="height:100%">
    <div v-if="reload" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
      <v-card
        width="47%"
        height="270px"
        class="round mb-3"
        elevation="0"
        outlined
        v-for="n in 6"
        :key="n+'A'"
      >
      <v-skeleton-loader
        type="card"
      ></v-skeleton-loader>
      </v-card>
    </div>
    <div v-else class="row d-flex justify-space-between pt-3">
      <v-col cols="4" elevation="0" max-width="100px" v-for="item in categories" :key="item.name"
        class="text-center pa-0" 
      >
      <v-card @click="goToDetail(item.link)" color="transparent" elevation="0">
        <v-card-text>
          <i :class="item.icon" class="text-24 primary--text pb-3"></i><br>
          <div class="text-12">{{ item.name }}</div>
        </v-card-text>
      </v-card>
      </v-col>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      reload:true,
      isMobileWidth:0,
      page:'',
      categories:[
        {name:'Tiktok', icon:'fab fa-tiktok', link:'tiktok'},
        {name:'Beauty', icon:'fas fa-spa', link:'beauty'}, 
        {name:'Food & Beverages', icon:'fas fa-hamburger', link:'Food&beverages'},
        {name:'Travel & Lifestyle', icon:'fas fa-plane', link:'travel&lifestyle'},
        {name:'Parenting', icon:'fas fa-baby', link:'parenting'}, 
        {name:'Home Decor', icon:'fas fa-laptop-house', link:'home-decor'}, 
        {name:'Fashion', icon:'fas fa-tshirt', link:'fashion'}, 
        {name:'Health & Sport', icon:'fas fa-dumbbell', link:'health&sport'},
        {name:'Gamers', icon:'fas fa-gamepad', link:'gamers'}, 
        {name:'Content Creator', icon:'fas fa-camera-retro', link:'content-creator'},
        {name:'Technology', icon:'fas fa-laptop', link:'technology'},
        {name:'Cooking', icon:'fas fa-cookie-bite', link:'cooking'},
      ]
    }
  },
  mounted(){
    this.page = this.$route.query.item
    if(this.isMobileWidth === 0){
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
          this.reload = false
      } else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
    }
    window.scrollTo(0,0);
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    goToDetail(category){
      this.$router.push({name:'category-detail', query: {item: category}}); 
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  }
}
</script>
