<template>
  <div>
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="400"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto pa-1"
            width="400"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto pa-1"
            width="400"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <v-row class="ma-4 mt-0 pt-0">
        <v-col cols="12">
          <category-all></category-all>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CategoryAll from './components/AllCategory.vue'
import axios from 'axios'

export default {
  name: 'category',
  metaInfo: {
    title: 'List Category'
  },
  components: {
    CategoryAll
  },
  setup() {
    return {
      isLoading:true,
    }
  },
  mounted(){
    // this.loadCampaign()
  },
  methods:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    dateFormat(value){
      return value.toString().slice(0, 10)
    }
  }
}
</script>
